<div
  *ngIf="showSnackBar"
  [ngClass]="{
    'snackbar-success': type === 'success',
    'snackbar-error': type === 'error',
    'snackbar-warning': type === 'warning'
  }"
>
  <div class="cont-icon">
    <img
      *ngIf="type === 'success'"
      style="width: 30px; height: 30px"
      src="./../../assets/illustrations/check-circle.svg"
      alt="Success"
    />
    <img
      *ngIf="type === 'error'"
      style="width: 30px; height: 30px"
      src="./../../assets/illustrations/cross-circle.svg"
      alt="Error"
    />
    <img
      *ngIf="type === 'warning'"
      style="width: 30px; height: 30px"
      src="./../../assets/illustrations/warning-circle.svg"
      alt="Warning"
    />
  </div>
  <div class="contenidos-snackbar">
    <h3>{{ tituloSnackBar }}</h3>
    <p>{{ mensajeSnackBar }}</p>
  </div>
  <div class="cont-equis">
    <img src="../../../assets/illustrations/close.svg" (click)="onClose()" alt="Close">
  </div>
</div>