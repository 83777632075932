import { Component, Input } from '@angular/core';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-no-content',
  standalone: true,
  imports: [CardModule],
  templateUrl: './no-content.component.html',
  styleUrl: './no-content.component.scss'
})
export class NoContentComponent {
    @Input() width: string = '100%';
    @Input() height: string = '100%';
    @Input() message: string = '';

}
