<ng-container *ngIf="loading; else loadedContent">
  <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
</ng-container>

<ng-template #loadedContent>
  <ng-container *ngIf="infoData; else noCommercialTemplate">
    <ng-container *ngTemplateOutlet="cardTemplate; context: { $implicit: infoData }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
</ng-template>

<ng-template #noCommercialTemplate>
  <p-card styleClass="card-container">
    <div class="content">
      <div class="header">
        <div class="title">
          <p-avatar icon="fa-regular fa-building" styleClass="mr-2" size="large" shape="circle" />
          <span class="title-text">Información comercial</span>
        </div>
      </div>
      <div class="body">
        <div class="info-block">
          <app-info-block [title]="'Dirección'">
            <span>Sin registro</span>
          </app-info-block>
          <app-info-block [title]="'Centros de gestión'">
            <span>Sin registro</span>
          </app-info-block>
          <app-info-block [title]="'Cobertura'">
            <span>Sin registro</span>
          </app-info-block>
        </div>
        <div class="categories">
          <app-info-block [title]="'Categorías'">
            <span>Sin registro</span>
          </app-info-block>
        </div>
      </div>
    </div>
  </p-card>
</ng-template>


<ng-template #cardTemplate let-commercial>
  <p-card styleClass="card-container">
    <div class="content">
      <div class="header">
        <div class="title">
          <p-avatar icon="fa-regular fa-building" styleClass="mr-2" size="large" shape="circle" />
          <span class="title-text">Información comercial</span>
        </div>
      </div>
      <div class="body">
        <div class="info-block">
          <app-info-block [title]="'Dirección'">
            <ng-container *ngIf="commercial; else skeletonInfoBlock">
              <span>{{ commercial.address }}</span>
            </ng-container>
            <ng-template #skeletonInfoBlock>
              <p-skeleton width="100%" height="1.071rem" />
            </ng-template>
          </app-info-block>
          <app-info-block [title]="'Centros de gestión'">
            <ng-container *ngIf="commercial; else skeletonInfoBlock">
              <app-tag [text]="infoProjects.length.toString()" [backgroundColor]="'#F1F5F9'" [fontSize]="'12px'" [fontWeight]="'500'" [textColor]="'#475569'" [icon]="'fa-regular fa-building'" (click)="showCGHistorical()">
              </app-tag>
            </ng-container>
          </app-info-block>
          <p-dialog header="Centros de Gestión Históricos" [modal]="true" [(visible)]="visibleCGHistorical" [style]="{ width: '50rem' }" [dismissableMask]="true">
            <p-table [value]="infoProjects">
              <ng-template pTemplate="header">
                <tr>
                    <th>Nombre</th>
                    <th>Monto total transado</th>
                    <th>Fecha último contrato</th>
                    <th>Estado contrato</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-proyect>
                <tr>
                    <td>{{ proyect.nombre }}</td>
                    <td>{{ formatToMillions(proyect.totalTransacciones) }}</td>
                    <td>{{ formatDate(proyect.fechaCreacionUltimoContrato) }}</td>
                    <td>
                      <p-chip [label]="getStateText(proyect.estado)" [style]="getStateStyle(proyect.estado)"></p-chip>
                  </td>
                </tr>
            </ng-template>
        </p-table>
          </p-dialog>
          <app-info-block [title]="'Cobertura'">
            <ng-container *ngIf="commercial; else skeletonInfoBlock">
              <app-tag [text]="commercial.coverages.length.toString()" [backgroundColor]="'#F1F5F9'" [fontSize]="'12px'" [fontWeight]="'500'" [textColor]="'#475569'" [icon]="'fa-regular fa-location-dot'" (click)="showCoverage()">
              </app-tag>
            </ng-container>
          </app-info-block>
          <p-dialog header="Lugares de Cobertura" [modal]="true" [(visible)]="visibleCoverages" [dismissableMask]="true">
            <p-table [value]="commercial.coverages">
              <ng-template pTemplate="header">
                <tr>
                  <th>País</th>
                  <th>Zona</th>
                  <th>Región</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-coverage>
                <tr>
                  <td>{{ coverage.country_name }}</td>
                  <td>{{ coverage.zone_name }}</td>
                  <td>{{ coverage.region_name }}</td>
                </tr>
              </ng-template>
            </p-table>
          </p-dialog>
        </div>
        <div class="categories">
          <span class="title">Categorías</span>
          <div class="tags">
            <ng-container *ngIf="commercial; else skeletonCategories">
              <ng-container *ngFor="let category of infoCategories">
                <app-tag [text]="category" [backgroundColor]="'#F1F5F9'" [fontSize]="'12px'" [fontWeight]="'500'"
                  [textColor]="'#475569'">
                </app-tag>
              </ng-container>
            </ng-container>
            <ng-template #skeletonCategories>
              <p-skeleton width="12.25rem" height="1.071rem" />
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </p-card>
</ng-template>