import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './_views/page-not-found/page-not-found.component';
import { authGuard } from './services/auth/auth.guard';
import { UnauthorizedComponent } from './_views/unauthorized/unauthorized.component';
import { HomeComponent } from './_views/detail.v2/home/home.component'; // Asegúrate de importar el componente


export const routes: Routes = [
    { path: '', redirectTo: 'pages', pathMatch: 'full' },
    {
        path: 'pages',
        canActivateChild: [authGuard],
        children: [
            {
                path: '',
                redirectTo: 'subcontractor',
                pathMatch: 'full'
            },
            {
                path: 'subcontractor',
                loadChildren: () => import('./_views/subcontractor/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'detail/:id',
                loadChildren: () => import('./_views/detail/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'detailv2/:origin/:externalId/:identificationNumber',
                component: HomeComponent
              }
        ]
    },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: '**', component: PageNotFoundComponent }
];
