import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuModule } from 'primeng/menu';
import { CommonModule } from '@angular/common';
import { BreadcrumbService } from '../../services/breadcrumb/breadcrumb.service';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [BreadcrumbModule, MenuModule, CommonModule],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  home: MenuItem = { icon: '' };
  items: MenuItem[] = [];
  private subscription!: Subscription;

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit() {
    this.home = this.breadcrumbService.getHomeItem();

    this.subscription = this.breadcrumbService.items$.subscribe(
      (items) => (this.items = items)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe(); // Evita fugas de memoria
  }
}
