import { Component } from '@angular/core';

@Component({
  selector: 'app-bank-info',
  standalone: true,
  imports: [],
  templateUrl: './bank-info.component.html',
  styleUrl: './bank-info.component.scss'
})
export class BankInfoComponent {

}
