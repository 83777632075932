import { Component, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardModule } from 'primeng/card';
import { AvatarModule } from 'primeng/avatar';
import { TagComponent } from '../../../components/tag/tag.component';
import { ChartModule } from 'primeng/chart';
import { Risk } from '../../../_models/risk.model';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { NoContentComponent } from '../../../components/no-content/no-content.component';
import { InfoBlockComponent } from './info-block/info-block.component';
import { RatingBarsComponent } from '../../../components/rating-bars/rating-bars.component';



@Component({
  selector: 'app-risk-info',
  standalone: true,
  imports: [CardModule, AvatarModule, FontAwesomeModule, TagComponent, 
    ChartModule, CommonModule, SkeletonModule, NoContentComponent, InfoBlockComponent, RatingBarsComponent],
  templateUrl: './risk-info.component.html',
  styleUrl: './risk-info.component.scss'
})
export class RiskInfoComponent implements OnInit{
  loading: boolean = true;
  risk: Risk | null = null;
  chartData: any = {
    labels: ['Puntaje judicial', 'Puntaje Cobranza Laboral', 'Puntaje Riesgo Medio'],
    datasets: []
  };
  chartOptions: any = {
    plugins: {
        legend: {
          display: false,
        }
    },
    scales: {
        y: {
            beginAtZero: true,
            max: 100,
            ticks: {
                color: '#64748B',
            },
            grid: {
                color: '#E2E8F0',
                drawBorder: false
            }
        },
        x: {
            ticks: {
                color: '#64748B'
            },
            grid: {
              color: '#E2E8F0',
              drawBorder: false
          }
        }
    }
  };
  
    // ... (chartOptions se mantiene igual)
  
    ngOnInit() {
      setTimeout(() => {
        this.loading = false;
        
        // 👇 Asignamos el objeto Risk completo (no propiedades individuales)
        this.risk = {
          labels: [
            { name: 'Puntaje judicial', value: 66 },
            { name: 'Puntaje Cobranza Laboral', value: 12 },
            { name: 'Puntaje Riesgo Medio', value: 99 }
          ],
          bankrupcy: false,
          monitored: false,
          riskLevel: {
            label: 'Alto',
            value: 5
          },
          lastUpdated: new Date('2023-10-01')
        };
  
        // Actualizamos el gráfico
        this.chartData = {
          ...this.chartData, // Mantenemos configuración existente
          datasets: [
            {
              data: this.risk.labels.map(label => label.value), // Más limpio
              backgroundColor: ['#FFE1CC', '#FFC399', '#FF9D57']
            }
          ]
        };
      }, 3000);
    }

  formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
}
