import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient,
    private cookieServices: CookieService) {
  }

  public getAccessToken(): string | null {
    return this.cookieServices.get('accessToken');
  }

  private saveAccessToken(accessToken: string) {
    this.cookieServices.set('accessToken', accessToken, 0, '/', environment.cookieDomain, true, 'Lax');
  }

  refreshToken(): Observable<void> {
    return this.http.get<{ accessToken: string }>(`${environment.urlApiSSO}/api/v1/autenticacion/refrescartoken?token=${this.getRefreshToken()}`)
      .pipe(map((data) => {
        if (data && data.accessToken !== '') {
          this.saveAccessToken(data.accessToken);
        } else {
          this.logout();
          throw new Error('No access token in response');
        }
      }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  private getRefreshToken(): string {
    return this.cookieServices.get('refreshToken') || '';
  }

  logout() {
    return this.cookieServices.delete('accessToken');
  }
}
