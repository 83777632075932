<div class="info-block-container">
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div class="body">
      <ng-container *ngIf="!tag; else tagTemplate">
        <ng-content></ng-content>
      </ng-container>
    </div>
  </div>
  
  <ng-template #tagTemplate>
    <app-tag
      [text]="tagText"
      [backgroundColor]="backgroundColor"
      [fontSize]="fontSize"
      [fontWeight]="fontWeight"
      [textColor]="textColor"
      [icon]="icon"
      [iconPosition]="true">
    </app-tag>
  </ng-template>