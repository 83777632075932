<ng-container *ngIf="loading; else loadedContent">
    <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
  </ng-container>
  
  <ng-template #loadedContent>
    <ng-container *ngIf="contact; else noContactTemplate">
      <ng-container *ngTemplateOutlet="cardTemplate; context: { $implicit: contact }"></ng-container>
    </ng-container>
  </ng-template>
  
  <ng-template #loadingTemplate>
    <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
  </ng-template>
  
  <ng-template #noContactTemplate>
    <p-card styleClass="card-container">
      <div class="content">
        <div class="header">
            <div class="title">
              <p-avatar icon="fa-regular fa-address-card" styleClass="mr-2" size="large" shape="circle" />
              <span class="title-text">Contactos</span>
            </div>
            <div class="header-button">
                  <p-button 
                  label="Agregar"
                  icon="fa-regular fa-plus"
                  iconPos="right"/>
            </div>
          </div>
        <div class="body">
            <app-no-content message="Este subcontratista no cuenta aún con datos de contacto"></app-no-content>
        </div>
      </div>
    </p-card>
  </ng-template>
  
  
  <ng-template #cardTemplate>
    <p-card styleClass="card-container">
      <div class="content">
        <div class="header">
          <ng-container *ngIf="contact; else skeletonTitle">
          <div class="title">
            <p-avatar icon="fa-regular fa-address-card" styleClass="mr-2" size="large" shape="circle" />
            <span class="title-text">Contactos ({{ contact.length }})</span>
          </div>
          </ng-container>
          <ng-template #skeletonTitle>
            <div class="title">
              <p-avatar icon="fa-regular fa-address-card" styleClass="mr-2" size="large" shape="circle" />
              <span class="title-text">Contactos</span>
            </div>
          </ng-template>
          <div class="header-button">
            <ng-container *ngIf="contact; else skeletonButton">
                <p-button 
                label="Agregar"
                icon="fa-regular fa-plus"
                iconPos="right"/>
            </ng-container>
            <ng-template #skeletonButton>
              <div class="skeleton-header-button">
                <p-button 
                label="Agregar"
                icon="fa-regular fa-plus"
                iconPos="right"
                disabled="true"/>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="body">
          <ng-container *ngIf="contact; else skeletonFilter">
            <div class="filter-content">
                <input 
                type="text" 
                placeholder="Buscar"
                pInputText 
                [(ngModel)]="value"/>
                <p-avatar 
                icon="fa-regular fa-filter"
                pBadge 
                badgeClass="custom-badge"
                value="4"/>
            </div>
          </ng-container>
          <ng-template #skeletonFilter>
            <div class="skeleton-filter">
              <input 
              type="text" 
              placeholder="Buscar"
              pInputText 
              [(ngModel)]="value" 
              disabled="true"/>
              <p-avatar 
              icon="fa-regular fa-filter"/>
          </div>
          </ng-template>
        <ng-container *ngIf="contact; else skeletonCarousel">
            <div class="contact-container">
              <p-carousel
                [value]="this.contact ? this.contact : []"
                [numVisible]="2"
                [numScroll]="1"
                [circular]="true"
                [responsiveOptions]="responsiveOptions">
                <ng-template let-contact pTemplate="item">
                  <div class="border-1 surface-border border-round m-2 p-3" [style]="{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '150px', background: '#F8FAFC'}">
                    <div class="mb-3 font-medium">
                      <div class="relative mx-auto">
                          <div class="item-header">
                            <p-avatar [label]="contact.names[0].toUpperCase()" styleClass="mr-2" shape="circle" />
                            {{ contact.names }}
                          </div>
                      </div>
                    </div>
                  <div class="mb-3 font-medium">
                      <div class="item-body">
                        <div class="body-content">
                          <div class="body-item">
                              {{ contact.phone_number }}
                          </div>
                          <div class="body-item">
                              {{ contact.email }}
                          </div>
                      </div>
                      </div>
                  </div>
                  <div class="mb-3 font-medium">
                    <div class="item-tag">
                      <ng-container *ngFor="let type of contact.types.slice(0, 3)">
                        <app-tag [text]="type" [backgroundColor]="'#F1F5F9'" [textColor]="'#475569'"></app-tag>
                      </ng-container>
                      <ng-container *ngIf="contact.types.length > 3">
                        <app-tag 
                          [text]="'+' + (contact.types.length - 3)" 
                          [backgroundColor]="'#F1F5F9'" 
                          [textColor]="'#475569'" 
                          [tooltipText]="contact.types.slice(3).join('\n')">
                        </app-tag>
                      </ng-container>
                    </div>
                  </div>
                  </div>
                </ng-template>
              </p-carousel>
          </div>
        </ng-container>
        <ng-template #skeletonCarousel>
          <div class="skeleton-carousel"> 
            <p-skeleton width="100%" height="100%"></p-skeleton>
          </div>
        </ng-template>
        </div>
      </div>
    </p-card>
  </ng-template>