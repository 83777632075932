import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { SkeletonModule } from 'primeng/skeleton';
import { AvatarModule } from 'primeng/avatar';
import { InfoBlockComponent } from './info-block/info-block.component';
import { Sii } from '../../../_models/sii.model';

@Component({
  selector: 'app-sii-info',
  standalone: true,
  imports: [    
    CommonModule,
    CardModule,
    SkeletonModule,
    AvatarModule,
    InfoBlockComponent
  ],
  templateUrl: './sii-info.component.html',
  styleUrl: './sii-info.component.scss'
})
export class SiiInfoComponent {
  loading = true;
  sii: Sii | null = null;

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
      this.sii = {
        business_start_status: 'SI',
        business_start_date: '2019-01-01',
        authorized_to_pay_taxes_in_foreign_currency: 'NO', 
        small_business_taxpayer: 'NO',
        hard_to_audit_taxpayer: 'NO',
        company_size: 'Gran empresa',
        annual_transaction_range: '600.000,01 a 1.000.000,00'
      };
      // this.sii = null;
    }, 3000);
  }

}