import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackBarService } from './snackBar.service';

@Component({
  selector: 'app-snack-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent implements OnInit {
  showSnackBar = false;
  type = 'success';
  tituloSnackBar = '';
  mensajeSnackBar = '';

  constructor(private snackBarService: SnackBarService) {}

  ngOnInit(): void {
    this.snackBarService.showSnackBar$.subscribe(
      (show) => (this.showSnackBar = show)
    );
    this.snackBarService.type$.subscribe((type) => (this.type = type));
    this.snackBarService.title$.subscribe(
      (title) => (this.tituloSnackBar = title)
    );
    this.snackBarService.message$.subscribe(
      (message) => (this.mensajeSnackBar = message)
    );
  }

  hideSnackbar() {
    this.snackBarService.hideSnackBar();
  }

  onClose() {
    this.hideSnackbar();
  }
}
