import { HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { InitService } from '../services/init/init.service';
import { inject } from '@angular/core';
import { catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const accessToken = inject(InitService).getAccessToken();
  const authService = inject(AuthService);

  const authReq = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
  });

  return next(authReq).pipe(
    catchError((err) => {
      if (err.status === HttpStatusCode.Unauthorized) {
        return authService.refreshToken().pipe(
          switchMap((res) => {
            const newReq = req.clone({
              setHeaders: {
                'Authorization': `Bearer ${authService.getAccessToken()}`,
              }
            });

            return next(newReq);
          }),
          catchError((refreshError) => {
            return throwError(() => new Error(refreshError));
          }),
        )
      }
      return throwError(() => new Error(err));
    })
  )
};
