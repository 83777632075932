<ng-container *ngIf="loading; else loadedContent">
  <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
</ng-container>

<ng-template #loadedContent>
  <ng-container *ngIf="risk; else noRiskTemplate">
    <ng-container *ngTemplateOutlet="cardTemplate; context: { $implicit: risk }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
</ng-template>

<ng-template #noRiskTemplate>
  <p-card styleClass="card-container">
    <div class="content">
      <div class="header">
        <div class="title">
          <p-avatar icon="fa-regular fa-chart-line-up" styleClass="mr-2" size="large" shape="circle" />
          <span class="title-text">Riesgos</span>
        </div>
      </div>
      <div class="body">
        <div class="tags">
          <div class="actions-left">
            <app-tag text="Descargar último informe" textColor="#64748B" fontSize="0.857rem" fontWeight="600" backgroundColor="#FFFF" border="0.071rem solid #E2E8F0" icon="fa-regular fa-arrow-down-to-line" [iconPosition]="false" height="2.286rem"></app-tag>
            <app-tag text="Calificar riesgo" textColor="#64748B" fontSize="0.857rem" fontWeight="600" backgroundColor="#FFFF" border="0.071rem solid #E2E8F0" height="2.286rem"></app-tag>
          </div>
          <div class="actions-rigth" >
            <app-tag text="Monitorear" textColor="#0F172A" fontSize="1rem" fontWeight="700" backgroundColor="#FFFF" border="0.214rem solid #E2E8F0" icon="fa-regular fa-eye" [iconPosition]="false" height="2.286rem"></app-tag>
          </div>
         </div>
         <div class="info-block">
          <app-info-block [title]="'Quiebra'">
              <span>Sin registro</span>
          </app-info-block>
          <app-info-block [title]="'Nivel de riesgo'">
                <span>Sin registro</span>
          </app-info-block>
        </div>
        <div class="chart-container">
          <app-no-content message="Este subcontratista no cuenta aún con información de puntaje de riesgo"></app-no-content>
        </div>
      </div>
    </div>
  </p-card>
</ng-template>


<ng-template #cardTemplate let-risk>
  <p-card styleClass="card-container">
    <div class="content">
      <div class="header">
        <div class="title">
          <p-avatar icon="fa-regular fa-chart-line-up" styleClass="mr-2" size="large" shape="circle" />
          <span class="title-text">Riesgos</span>
        </div>
        <div class="date">
          <ng-container *ngIf="risk; else skeletonDateBlock">
              <p>Última actualización: {{ risk?.lastUpdated ? formatDate(risk.lastUpdated) : '' }}</p>
          </ng-container>
          <ng-template #skeletonDateBlock>
            <div class="skeleton-container">
              <p-skeleton width="100%" height="1.071rem"></p-skeleton>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="body">
        <div class="tags">
          <div class="actions-left">
            <app-tag text="Descargar último informe" textColor="#64748B" fontSize="0.857rem" fontWeight="600" backgroundColor="#FFFF" border="0.071rem solid #E2E8F0" icon="fa-regular fa-arrow-down-to-line" [iconPosition]="false" height="2.286rem"></app-tag>
            <app-tag text="Calificar riesgo" textColor="#64748B" fontSize="0.857rem" fontWeight="600" backgroundColor="#FFFF" border="0.071rem solid #E2E8F0" height="2.286rem"></app-tag>
          </div>
          <div class="actions-rigth" >
            <app-tag text="Monitorear" textColor="#0F172A" fontSize="1rem" fontWeight="700" backgroundColor="#FFFF" border="0.214rem solid #E2E8F0" icon="fa-regular fa-eye" [iconPosition]="false" height="2.286rem"></app-tag>
          </div>
         </div>
         <div class="info-block">
          <app-info-block [title]="'Quiebra'">
            <ng-container *ngIf="risk; else skeletonInfoBlock">
              <span>{{ this.risk?.bankrupcy ? 'Si' : 'No' }}</span>
            </ng-container>
            <ng-template #skeletonInfoBlock>
              <p-skeleton width="33%" height="1.071rem" />
            </ng-template>
          </app-info-block>
          <app-info-block [title]="'Nivel de riesgo'">
            <ng-container *ngIf="risk; else skeletonRiskBlock">
              <div class="risk-level">
                <app-rating-bars [totalBars]="5" [barLevel]="this.risk?.riskLevel?.value ?? 0"></app-rating-bars>
                <span>{{ this.risk?.riskLevel?.label }}</span>
              </div>
            </ng-container>
            <ng-template #skeletonRiskBlock>
              <div class="risk-level">
                <app-rating-bars [totalBars]="5"></app-rating-bars>
                <p-skeleton width="7.143rem" height="1.071rem"></p-skeleton>
              </div>
            </ng-template>
          </app-info-block>
        </div>
        <div class="chart-container">
          <p-chart type="bar" [data]="chartData" [options]="chartOptions" />
        </div>
      </div>
    </div>
  </p-card>
</ng-template>