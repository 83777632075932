<p-tag 
  [styleClass]="'custom-tag'"
  [style]="{
    'font-size': fontSize, 
    'font-weight': fontWeight,
    'background': backgroundColor,
    'color': textColor,
    'border': border,
    'height': height,
    'width': width
  }">
  <div class="tag-content">
    @if (icon) {
      <i 
        [class]="icon" 
        [style]="{
          'order': iconPosition ? 0 : 1
        }">
      </i>
    }
    <span class="tag-text"[pTooltip]="tooltipText" tooltipPosition="bottom">
      {{ text }}
    </span>
  </div>
</p-tag>