<div class="info-block">
  <span class="info-title">{{ title }}</span>
  <div class="info-content">
    <ng-container *ngIf="isStringValue">
      <p-skeleton *ngIf="loading" width="5.89rem" height="1.14rem"></p-skeleton>
      <span *ngIf="!loading" class="info-value">{{ value }}</span>
    </ng-container>

    <ng-container *ngIf="!isStringValue">
      <ng-container *ngIf="ratingValue !== undefined">
        <app-rating [loading]="this.loading"
          [value]="ratingValue"
          [count]="ratingCount">
        </app-rating>
      </ng-container>
    </ng-container>
  </div>
</div>
