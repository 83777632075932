import { Component } from '@angular/core';

@Component({
  selector: 'unauthorized',
  standalone: true,
  imports: [],
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.sass',
})
export class UnauthorizedComponent {}
