<ng-container *ngIf="!loading; else loadingTemplate">
  <p-card styleClass="card-container">
    <div>
      <div class="profile-header">
        <p-avatar styleClass="profile-avatar p-avatar-xlarge" [label]="infoData.company_name[0]"/>
        <h2 class="profile-name">{{ infoData.company_name }}</h2>
      </div>
      <div class="status-container">
        <app-tag 
          [text]="infoData.current_state.label"
          [backgroundColor]="profileData.currentState.color.background"
          [textColor]="profileData.currentState.color.text"
          fontWeight="500"
          fontSize="0.857rem">
        </app-tag>
      </div>
      <div class="ratings-section">
        <app-info-block 
          [loading]="this.loading" 
          title="RUT" 
          [value]="infoData.identification_number"/>
        <app-info-block 
          [loading]="this.loading"
          title="Evaluación Privada" 
          [ratingValue]="infoData.buyer_rating" 
          [ratingCount]="infoData.buyer_rating_count"/>
        <app-info-block 
          [loading]="this.loading"
          title="Evaluación Comunidad" 
          [ratingValue]="infoData.general_rating" 
          [ratingCount]="infoData.general_rating_count"/>
      </div>
    </div>
  </p-card>
</ng-container>

<ng-template #loadingTemplate>
  <p-card styleClass="card-container">
    <div>
      <div>
        <p-skeleton width="4rem" height="4rem" [style]="{ margin: '0rem 0rem 1rem 0rem' }" />
        <p-skeleton width="35.714rem" height="3.6rem" [style]="{ margin: '0rem 0rem 1rem 0rem' }" />
      </div>
      <div [style]="{ display: 'flex', flexDirection: 'row', gap: '3.429rem'}">
        <p-skeleton width="7.143rem" height="1.571rem"/>
        <p-skeleton width="10rem" height="2.071rem"/>
      </div>
      <div class="ratings-section">
        <app-info-block title="RUT" [value]=""/>
        <app-info-block 
          title="Evaluación Privada" 
          [ratingValue]="profileData.personalRating.value" 
          [ratingCount]="profileData.personalRating.count"/>
        <app-info-block 
          title="Evaluación Comunidad" 
          [ratingValue]="profileData.publicRating.value" 
          [ratingCount]="profileData.publicRating.count"/>
      </div>
    </div>
  </p-card>
</ng-template>