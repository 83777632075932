<ng-container *ngIf="loading; else contentTemplate">
  <span class="rating-container">
    <p-skeleton width="1.42rem" height="1.21rem" />
    <p-rating 
      [(ngModel)]="this.value" 
      [readonly]="true" 
      [cancel]="false" 
      styleClass="custom-rating"
      [ngClass]="{'loading-rating': loading}" />
    <p-skeleton width="1.71rem" height="1.21rem" />
  </span>
</ng-container>

<ng-template #contentTemplate>
  <ng-container *ngIf="value !== null && value !== 0 && count !== null && count !== 0; else noRating">
    <span class="rating-container">
      {{ value }}
      <p-rating 
        [(ngModel)]="roundedValue" 
        [readonly]="true" 
        [cancel]="false"
        styleClass="custom-rating" />
      ({{ count }})
    </span>
  </ng-container>
  
  <ng-template #noRating>
    <span class="rating-container">Sin registro</span>
  </ng-template>
</ng-template>