import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { SkeletonModule } from 'primeng/skeleton';
import { NoContentComponent } from '../../../components/no-content/no-content.component';
import { InputTextModule } from 'primeng/inputtext';
import { ChartModule } from 'primeng/chart';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { CarouselModule } from 'primeng/carousel';
import { ContactModel } from '../../../_models/contacts.model';
import { TagComponent } from '../../../components/tag/tag.component';
import { TagModule } from 'primeng/tag';



@Component({
  selector: 'app-contact-info',
  standalone: true,
  imports: [    
    CommonModule,
    CardModule,
    SkeletonModule,
    AvatarModule, 
    NoContentComponent,
    ChartModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    AvatarGroupModule,
    BadgeModule,
    CarouselModule,
    TagComponent,
    TagModule
  ],
  templateUrl: './contact-info.component.html',
  styleUrl: './contact-info.component.scss'
})
export class ContactInfoComponent implements OnInit{
  value: string = '';
  loading: boolean = true;
  responsiveOptions: any[] | undefined;
  contact: ContactModel[] | null = null;
  
  ngOnInit() {
    setTimeout(() => {
      this.loading = false;
      this.responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];
      this.contact = [
        {
          id: '1',
          names: 'Icafal S.A.',
          phone_number: '+56 2 1234 5678',
          email: 'icafal@gmail.com',
          types: [
            'Venta',
            'Comercial',
            'Cliente'
          ]
        },
        {
          id: '2',
          names: 'salfa comercial S.A.',
          phone_number: '+56 2 9876 5432',
          email: '',
          types: [
            'Venta',
            'Cliente'
          ]
        },
        {
          id: '3',
          names: '3',
          phone_number: '+56 2 9876 5432',
          email: '',
          types: [
            'Venta',
            'Comercial',
            'Cliente',
            'Amigos',
            'Calidad',
            'Bueno'
          ]
        },
        {
          id: '4',
          names: '4',
          phone_number: '+56 2 1234 5678',
          email: 'icafal@gmail.com',
          types: [
            'Venta',
            'Comercial',
            'Cliente',
            'Amigos',
            'Calidad'
          ]
        },
        {
          id: '5',
          names: '5',
          phone_number: '+56 2 1234 5678',
          email: 'icafal@gmail.com',
          types: [
            'Venta',
            'Comercial',
            'Cliente'
          ]
        },
        {
          id: '6',
          names: '6',
          phone_number: '+56 2 1234 5678',
          email: 'icafal@gmail.com',
          types: [
            'Venta',
            'Comercial',
            'Cliente'
          ]
        },
        {
          id: '7',
          names: '7',
          phone_number: '+56 2 1234 5678',
          email: 'icafal@gmail.com',
          types: [
            'Venta',
            'Comercial',
            'Cliente'
          ]
        }

      ]

    }, 3000);
  }
}
