import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-info',
  standalone: true,
  imports: [],
  templateUrl: './legal-info.component.html',
  styleUrl: './legal-info.component.scss'
})
export class LegalInfoComponent {

}
