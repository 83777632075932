import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TagComponent } from '../../../../components/tag/tag.component';

@Component({
  selector: 'app-info-block',
  standalone: true,
  imports: [CommonModule, TagComponent],
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss'],
})
export class InfoBlockComponent {
  @Input() title: string = ''; // Título del bloque de información
  @Input() tag: boolean = false; // Indica si se debe mostrar un tag
  @Input() tagText: string = ''; // Texto del tag
  @Input() icon: string = ''; // Ícono del tag

  // Estilos predefinidos para el tag
  @Input() backgroundColor: string = '#F1F5F9';
  @Input() fontSize: string = '12px';
  @Input() fontWeight: string = '500';
  @Input() textColor: string = '#475569';
}