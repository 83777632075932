import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RatingModule } from 'primeng/rating';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-rating',
  standalone: true,
  imports: [
    RatingModule, 
    FormsModule,
    CommonModule,
    SkeletonModule
  ],
  templateUrl: './rating.component.html',
  styleUrl: './rating.component.scss'
})
export class RatingComponent {
  private _value: number = 0;
  @Input() count: number = 0;
  @Input() loading: boolean = true;
  
  roundedValue: number = 0;

  @Input()
  set value(val: number) {
    this._value = val;
    this.roundedValue = Math.round(val);
  }

  get value(): number {
    return this._value;
  }
}