import { Component, input, Input, SimpleChanges } from '@angular/core';
import { CardModule } from 'primeng/card';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TagComponent } from '../../../components/tag/tag.component';
import { InfoBlockComponent } from './info-block/info-block.component';
import { ProfileData } from '../../../_models/profile-data.model';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { CompanyService } from '../../../services/company/company.service';
import { CompanyInformationBase } from '../../../_models/company.model';

@Component({
  selector: 'app-page-banner',
  standalone: true,
  imports: [
    CardModule,
    AvatarModule,
    AvatarGroupModule,
    FontAwesomeModule,
    InfoBlockComponent,
    TagComponent,
    CommonModule,
    SkeletonModule,
  ],
  templateUrl: './page-banner.component.html',
  styleUrl: './page-banner.component.scss',
})
export class PageBannerComponent {

  @Input() origin!: string;
  @Input() externalId!: string;
  @Input() identificationNumber!: string;
  @Input() companyId!: string;

  infoData: CompanyInformationBase = <CompanyInformationBase>{};

  loading: boolean = true;
  profileData: ProfileData = {
    name: '',
    rut: '',
    currentState: {
      label: '',
      color: {
        background: '',
        text: '',
      },
    },
    personalRating: {
      value: 0,
      count: 0,
    },
    publicRating: {
      value: 0,
      count: 0,
    },
  };

  constructor(
    public companyServices: CompanyService

  )
  {

  }

  ngOnInit(): void {
  this.loadInfo();
  }

  loadInfo() {
    if (this.origin && this.externalId && this.identificationNumber)
    {
      this.companyServices.getInformationBase(this.origin, this.externalId, this.identificationNumber, this.companyId)
      .subscribe( x => {
        this.loading = false;
        this.infoData = x
        this.infoData.current_state= {
          label: 'Activo',
          color: {
            background: 'var(--color-tag-success-background)',
            text: 'var(--color-tag-success-text)',
          },
        }
      }
      )
    }
  }
}

  // ngOnChanges(changes: SimpleChanges) {
  //   // console.log('changes')
  //   // console.log(changes)
  //   // if (changes['origin'] && changes['origin'].currentValue) {
  //   //   console.log('origin recibido:', this.origin);
  //   //   // Aquí ya tienes el id y puedes ejecutar la lógica que necesites
  //   // }
  // }
