import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-rating-bars',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rating-bars.component.html',
  styleUrl: './rating-bars.component.scss'
})
export class RatingBarsComponent implements OnChanges {
  @Input() totalBars: number = 5; // Número total de barras
  @Input() activeBars: number = 0; // Cuántas están llenas (se actualizará según el riesgo)
  @Input() inactiveColor: string = '#E2E8F0'; // Color de barras inactivas
  @Input() barLevel: number = 0; // Nivel de riesgo (0-5)
  activeColor: string = ''; // Color de barras activas (se calculará según el riesgo)

  // Método que se ejecuta cuando cambia alguna propiedad de entrada (@Input)
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['barLevel']) {
      this.updateBarsAndColor();
    }
  }

  // Actualiza el número de barras activas y el color en función del riesgo
  updateBarsAndColor(): void {
    if (this.barLevel === 0) {
      this.activeBars = 1;
      this.activeColor = '#22C55E'; // verde oscuro
    } else if (this.barLevel === 1) {
      this.activeBars = 2
      this.activeColor = '#84CC16'; // verde
    } else if (this.barLevel === 3) {
      this.activeBars = 3
      this.activeColor = '#EAB308'; // Amarillo
    } else if (this.barLevel === 4) {
      this.activeBars = 4
      this.activeColor = '#FF6A00'; // naranjo
    } else {
      this.activeBars = 5
      this.activeColor = '#EF4444'; // rojo
    }
  }
}