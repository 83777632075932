import { HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { inject } from "@angular/core";
import { InitService } from "../services/init/init.service";

export function JWTInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const accessToken = inject(InitService).getAccessToken();
    if (accessToken) {
        req = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
        });
    }
    return next(req).pipe(tap(event => {
        if (event.type === HttpEventType.Response) {
            console.log(req.url, 'returned a response with status', event.status);
        }
    }));
}