<ng-container *ngIf="loading; else loadedContent">
    <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
  </ng-container>
  
  <ng-template #loadedContent>
    <ng-container *ngIf="sii; else noSiiTemplate">
      <ng-container *ngTemplateOutlet="cardTemplate; context: { $implicit: sii }"></ng-container>
    </ng-container>
  </ng-template>
  
  <ng-template #loadingTemplate>
    <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
  </ng-template>

  <ng-template #noSiiTemplate>
    <p-card styleClass="card-container">
        <div class="content">
          <div class="header">
            <div class="title">
              <p-avatar icon="fa-regular fa-dollar-sign" styleClass="mr-2" size="large" shape="circle" />
              <span class="title-text">Información SII</span>
            </div>
          </div>
          <div class="body">
            <div class="info-block">
              <app-info-block [title]="'Inicio de actividades'">
                  <span>Sin registro</span>
              </app-info-block>
              <app-info-block [title]="'Fecha inicio de actividades'">
                    <span>Sin registro</span>
                </app-info-block>
                <app-info-block [title]="'Autorizado para declarar y pagar sus impuestos en moneda extranjera'">
                    <span>Sin registro</span>
                </app-info-block>
            </div>
            <div class="info-block">
              <app-info-block [title]="'Contribuyente es empresa de menor tamaño'">
                  <span>Sin registro</span>
              </app-info-block>
              <app-info-block [title]="'Contribuyente de difícil fiscalización'">
                    <span>Sin registro</span>
                </app-info-block>
              <app-info-block [title]="'Tamaño de la empresa'">
                    <span>Sin registro</span>
              </app-info-block>
            </div>
            <div class="info-block">
              <app-info-block [title]="'Rango de transacción anual'">
                  <span>Sin registro</span>
              </app-info-block>
              <app-info-block [title]="''">
                    <span></span>
                </app-info-block>
                <app-info-block [title]="''">
                    <span></span>
                </app-info-block>
            </div>
          </div>
        </div>
      </p-card>
  </ng-template>
  
  
  <ng-template #cardTemplate let-sii>
    <p-card styleClass="card-container">
      <div class="content">
        <div class="header">
          <div class="title">
            <p-avatar icon="fa-regular fa-dollar-sign" styleClass="mr-2" size="large" shape="circle" />
            <span class="title-text">Información SII</span>
          </div>
        </div>
        <div class="body">
          <div class="info-block">
            <app-info-block [title]="'Inicio de actividades'">
              <ng-container *ngIf="sii; else skeletonInfoBlock">
                <span>{{ sii.business_start_status }}</span>
              </ng-container>
              <ng-template #skeletonInfoBlock>
                <p-skeleton width="100%" height="1.071rem" />
              </ng-template>
            </app-info-block>
            <app-info-block [title]="'Fecha inicio de actividades'">
                <ng-container *ngIf="sii; else skeletonInfoBlock">
                    <span>{{ sii.business_start_date }}</span>
                </ng-container>
              </app-info-block>
              <app-info-block [title]="'Autorizado para declarar y pagar sus impuestos en moneda extranjera'">
                <ng-container *ngIf="sii; else skeletonInfoBlock">
                    <span>{{ sii.authorized_to_pay_taxes_in_foreign_currency }}</span>
                </ng-container>
              </app-info-block>
          </div>
          <div class="info-block">
            <app-info-block [title]="'Contribuyente es empresa de menor tamaño'">
              <ng-container *ngIf="sii; else skeletonInfoBlock">
                <span>{{ sii.small_business_taxpayer }}</span>
              </ng-container>
              <ng-template #skeletonInfoBlock>
                <p-skeleton width="100%" height="1.071rem" />
              </ng-template>
            </app-info-block>
            <app-info-block [title]="'Contribuyente de difícil fiscalización'">
                <ng-container *ngIf="sii; else skeletonInfoBlock">
                    <span>{{ sii.hard_to_audit_taxpayer }}</span>
                </ng-container>
              </app-info-block>
            <app-info-block [title]="'Tamaño de la empresa'">
                <ng-container *ngIf="sii; else skeletonInfoBlock">
                    <span>{{ sii.company_size }}</span>
            </ng-container>
            </app-info-block>
          </div>
          <div class="info-block">
            <app-info-block [title]="'Rango de transacción anual'">
              <ng-container *ngIf="sii; else skeletonInfoBlock">
                <span>{{ sii.annual_transaction_range }}</span>
              </ng-container>
              <ng-template #skeletonInfoBlock>
                <p-skeleton width="100%" height="1.071rem" />
              </ng-template>
            </app-info-block>
            <app-info-block [title]="''">
                  <span></span>
              </app-info-block>
              <app-info-block [title]="''">
                  <span></span>
              </app-info-block>
          </div>
        </div>
      </div>
    </p-card>
  </ng-template>