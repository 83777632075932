// auth.service.ts

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private accessTokenSubject: BehaviorSubject<string | null>;
  public currentAccessToken: Observable<string | null>;

  constructor(private http: HttpClient) {
    this.accessTokenSubject = new BehaviorSubject<string | null>(null);
    this.currentAccessToken = this.accessTokenSubject.asObservable();
  }

  public getAccessToken(): string | null {
    return this.accessTokenSubject.value;
  }

  private saveAccessToken(token: string) {
    this.accessTokenSubject.next(token);
    localStorage.setItem('accessToken', token);
  }

  refreshToken(): Observable<void> {
    return this.http
      .get<{ accessToken: string }>(
        `${environment.urlApiSSO}/api/v1/autenticacion/refrescartoken?token=${this.getRefreshToken()}`
      )
      .pipe(
        map((data) => {
          if (data && data.accessToken !== '') {
            this.saveAccessToken(data.accessToken);
          } else {
            this.logout();
            throw new Error('No access token in response');
          }
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  private getRefreshToken(): string {
    return localStorage.getItem('refreshToken') || '';
  }

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }
}
