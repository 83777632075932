import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const isAuthenticated = !!authService.getAccessToken();

  // if (!isAuthenticated) {
  //   router.navigate(['/unauthorized']);
  //   return false;
  // }

  return true;
};
