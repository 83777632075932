<p-card> 
    <app-page-banner [origin]="subcontractorOrigin" [externalId]="subcontractorExternalId" [identificationNumber]="subcontractorIdentificationNumber" [companyId]="companyId"> </app-page-banner>
    <div class="grid-container">
        <app-risk-info></app-risk-info>
        <app-commercial-info [origin]="subcontractorOrigin" [externalId]="subcontractorExternalId" [companyId]="companyId"></app-commercial-info>
        <app-sii-info></app-sii-info>
        <app-contact-info></app-contact-info>
        <app-bank-info></app-bank-info>
        <app-legal-info></app-legal-info>
    </div>
    <app-dashboard></app-dashboard>
    <app-documents></app-documents>
</p-card>