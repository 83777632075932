import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { SkeletonModule } from 'primeng/skeleton';
import { AvatarModule } from 'primeng/avatar';
import { InfoBlockComponent } from './info-block/info-block.component';
import { TagComponent } from '../../../components/tag/tag.component';
import { CompanyHistoricsProjects, CompanyInformationComercial } from '../../../_models/company.model';
import { CompanyService } from '../../../services/company/company.service';
import { CategoryService } from '../../../services/filter/categories/categories.service';

import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import { RegisterService } from '../../../services/register/register.service';

@Component({
  selector: 'app-commercial-info',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    SkeletonModule,
    AvatarModule,
    InfoBlockComponent,
    TagComponent,
    DialogModule,
    TableModule,
    ChipModule
  ],
  templateUrl: './commercial-info.component.html',
  styleUrls: ['./commercial-info.component.scss'],
})
export class CommercialInfoComponent {

  @Input() origin!: string;
  @Input() externalId!: string;
  @Input() companyId!: string;

  visibleCGHistorical = false;
  visibleCoverages = false;

  loading = true;
  infoData: CompanyInformationComercial = {
    address: "",
    comuna_id: 0,
    comuna_name: "",
    region_id: 0,
    region_name: "",
    country_id: "",
    country_name: "",

    keywords: "",

    projects: [],
    coverages: []

  }

  infoCategories: string[] = [];
  infoProjects: CompanyHistoricsProjects[] = [];
  deadline = '20200101';

  constructor(
    public companyServices: CompanyService,
    public categoriesServices: CategoryService,
    public registerService: RegisterService
  )
  {

  }




  ngOnInit(): void {

    this.loadData();
    // setTimeout(() => {
    //   this.loading = false;
    //   this.commercial = {
    //     address: 'Frat Camilo Henríquez 859, Las Condes, Santiago, RM, Chile',
    //     proyects: [
    //       'Construcción de la Ruta 5 Sur'
    //     ],
    //     coverage: [
    //       { country: 'Chile', zone: 'Santiago', region: 'Metropolitana' },
    //       { country: 'Chile', zone: 'Santiago', region: 'Metropolitana' },
    //     ],
    //     categories: [
    //       'Construcción',
    //       'Ingeniería',
    //       'Obras Civiles',
    //       'Obras Viales',
    //       'Obras Hidráulicas',
    //       'Construcción',
    //       'Ingeniería',
    //       'Obras Civiles',
    //       'Obras Viales',
    //       'Obras Hidráulicas',
    //       'Construcción',
    //       'Ingeniería',
    //       'Obras Civiles',
    //       'Obras Viales',
    //       'Obras Hidráulicas'
    //     ],
    //   };
    //   // this.commercial = null;
    // }, 3000);
  }

  loadData() {
    if (this.origin && this.externalId)
      {
        this.companyServices.getInformationComercial(this.origin, this.externalId, this.companyId)
        .subscribe( x => {
          this.loading = false;
          this.infoData = x
        }
        )

        this.categoriesServices.getCategoriesByExternalReference(this.origin, this.externalId)
        .subscribe( x => {
          this.infoCategories = x.map( y => y.name);
        }
        )

        this.registerService
              .getHistoricProjectsByExternalReference(this.companyId, this.origin, this.externalId, this.deadline)
              .subscribe((projects: CompanyHistoricsProjects[]) => {
                this.infoProjects = projects;
              });
      }
  }

  showCGHistorical() {
    this.visibleCGHistorical = true;
  }

  showCoverage() {
    this.visibleCoverages = true;
  }

  formatToMillions(value?: number): string {
    const actualValue = value ?? 0; // Si value es null o undefined, se asigna 0

    if (actualValue <= 0) {
      return '0 MM';
    } else if (actualValue < 100_000) {
      return '0,1 MM';
    } else if (actualValue < 1_000_000) {
      const millions = Math.trunc((actualValue / 1_000_000) * 10) / 10;
      const commaSeparated = millions.toFixed(1).replace('.', ',');
      return `${commaSeparated} MM`;
    } else if (actualValue >= 1_000_000) {
      const millions = Math.round(actualValue / 1_000_000);
      const pointSeparated = millions.toLocaleString('es').replace(',', '.');
      return `${pointSeparated} MM`;
    } else if (actualValue >= 100_000) {
      const millions = Math.round((actualValue / 1_000_000) * 10) / 10;
      return `${millions.toFixed(1)} MM`;
    } else {
      return '0 MM';
    }
  }

  formatDate(fecha: string): string {
    const date = new Date(fecha);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
    return date.toLocaleDateString('es-ES', options).replace(',', '');
  }

  getStateText(estado: number): string {
    return estado === 0 ? 'Inactivo' : 'Activo';
  }

  getStateStyle(estado: number): any {
    return {
      'background-color':
        estado === 1 ? 'var(--color-success)' : 'var(--color-B4)',
      color: 'white',
    };
  }


}