import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-tag',
  standalone: true,
  imports: [TagModule, TooltipModule, CommonModule],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss'
})
export class TagComponent {
  @Input() fontSize: string = '';
  @Input() fontWeight: string = '';
  @Input() text: string = '';
  @Input() height: string = '';
  @Input() width: string = '';
  @Input() backgroundColor: string = '';
  @Input() textColor: string = '';
  @Input() border: string = ''; // 1px solid #000
  @Input() icon: string = ''; // fa-icon
  @Input() iconPosition: boolean = true;  //true = left, false = right
  @Input() tooltipText: string = '';
}
