import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BankInfoComponent } from '../bank-info/bank-info.component';
import { ContactInfoComponent } from '../contact-info/contact-info.component';
import { DocumentsComponent } from '../documents/documents.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { LegalInfoComponent } from '../legal-info/legal-info.component';
import { PageBannerComponent } from '../page-banner/page-banner.component';
import { RiskInfoComponent } from '../risk-info/risk-info.component';
import { SiiInfoComponent } from '../sii-info/sii-info.component';
import { CommercialInfoComponent } from '../commercial-info/commercial-info.component';
import { CardModule } from 'primeng/card';
import { InitService } from '../../../services/init/init.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  imports: [BankInfoComponent, CommercialInfoComponent, ContactInfoComponent, DocumentsComponent,
     DashboardComponent, LegalInfoComponent, PageBannerComponent, RiskInfoComponent, 
     SiiInfoComponent, CardModule],
  standalone: true
})
export class HomeComponent implements OnInit {
  
  subcontractorId: string = '';
  subcontractorOrigin: string = '';
  subcontractorExternalId: string = '';
  subcontractorIdentificationNumber: string = '';
  companyId: string = '';

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private initService: InitService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.companyId = this.initService.getCompanyGuidCookie();
      this.subcontractorOrigin = params.get('origin')!;
      this.subcontractorExternalId = params.get('externalId')!;
      this.subcontractorIdentificationNumber = params.get('identificationNumber')!;
      this.subcontractorId = params.get('id')!;
    });

    //console.log(this.subcontractorOrigin)
  }

  goBack(): void {
    this.location.back();
  }
}
