import { Component, Input } from '@angular/core';
import { RatingComponent } from '../../../../components/rating/rating.component';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-info-block',
  standalone: true,
  imports: [RatingComponent, CommonModule, SkeletonModule],
  templateUrl: './info-block.component.html',
  styleUrl: './info-block.component.scss',
})
export class InfoBlockComponent {
  @Input() title: string = '';
  @Input() value: any;
  @Input() ratingValue?: any;
  @Input() ratingCount?: any;
  @Input() loading: boolean = true;

  get isStringValue(): boolean {
    return typeof this.value === 'string';
  }
}
