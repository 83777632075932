import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { environment } from '../environments/environment';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SnackBarComponent, BreadcrumbComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.sass'
})
export class AppComponent implements OnInit {
  title = 'spa_iregistro';
  constructor() {

  }

  ngOnInit(): void {
    if (environment.newRelic) {
      this.loadNewRelicScript(environment.newRelic);
    }
  }

  private loadNewRelicScript(url: string) {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.head.appendChild(script);
  }
}